







































































































































.trainingPage {
  .wrapper {
    margin-top: 100px;
  }
  .videoWrapper {
    height: 400px;
    max-width: 680px;
    width: 90vw;
    margin: auto;
    position: relative;
    // border:15px solid #333;
    // -webkit-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.15);
    // box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.15);
    // border-radius: 10px;
  }
}
